* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

html {
    scroll-behavior: smooth;
}

#header {
    background-color: black;
    position: sticky;
    top: 0;
    z-index: 2000;

    .menu {
        margin: 0 150px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            color: white;
            font-size: 60px;
            display: flex;
            align-items: center;

            img {
                height: 80px;
                width: 100%;
            }

        }

        .menuContent {
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 50px;


                li {
                    color: white;
                    font-size: 18px;
                    list-style: none;
                    cursor: pointer;

                    &::after {
                        content: '';
                        height: 1px;
                        background-color: #00F0FF;
                        width: 0;
                        margin-top: 2px;
                        display: block;
                        transition: all .5s;
                    }

                    &:hover {
                        &::after {
                            width: 100%;

                        }
                    }

                    a {
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }

        .email {
            margin-top: 8px;

            a {
                text-decoration: none;
                color: white;

                i {
                    color: white;
                }
            }
        }

        .phone {

            a {
                text-decoration: none;
                color: white;

                i {
                    color: white;
                }
            }
        }

    }

    .animateMenuIconWrap {
        // position: relative;

        .animateMenuIcon {
            height: 80px;
            width: 80px;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: #00F0FF;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transform: translateX(-30px);
            animation: moveRight 5s linear infinite;
        }

        .menuBar {
            position: fixed;
            top: 30px;
            left: 25px;
            color: white;
            font-size: 24px;
            z-index: 2;
            cursor: pointer;

        }
    }

}

#banner {
    // height: 815px;
    padding: 120px 0 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    .leftBanner {
        width: 630px;

        h1.mainTitle {
            width: 630px;
            // height: 240px;
            font-weight: 600;
            font-size: 68px;
            animation: opacity 2s;
        }

        p {
            margin-top: 20px;
            font-size: 18px;
            padding-right: 20px;
            animation: opacity 3s;
            color: #555555;

        }


        button {
            margin-top: 30px;
            padding: 10px 20px;
            background-color: #20B99C;
            color: white;
            font-size: 18px;
            border: none;
            border-radius: 16px 0 16px 0;
        }
    }

    .bannerImg {
        width: 560px;
        height: 460px;
        animation: upDown infinite 1s alternate;

        img {
            width: 100%;
            height: 100%;
            border-radius: 80px 0 80px 0;
        }
    }

}

@keyframes upDown {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);

    }

    // 100% {
    //     transform: translateY(0px);

    // }
}

#services {
    padding: 100px 0 20px 20px;
    display: flex;
    gap: 20px;
    justify-content: center;

    .serviceItem {
        padding: 40px;
        width: 430px;
        border: 1px solid silver;
        cursor: pointer;
        transition: all .5s;

        img {
            margin-bottom: 25px;
            filter: grayscale(1);
            transition: all .5s;

        }

        h4 {
            margin-bottom: 15px;
            transition: all .5s;
            font-size: 28px;

        }

        p {
            color: #555555;
            transition: all .5s;

        }

        &:hover {
            background-color: black;

            img {
                scale: 1.1;


            }

            h4,
            p {
                color: white;
                transform: translateY(-5px);
                scale: 1.01;
            }
        }

    }
}


#projects {
    padding: 100px 0 100px;

    .projectContent {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 100px;


        .leftProject {
            width: 630px;

            h1 {
                font-size: 60px;
            }

            img {
                width: 100%;
            }
        }

        .rightProject {
            width: 600px;

            ul {
                li {
                    font-size: 28px;
                    font-weight: 600;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid silver;
                    list-style: none;
                    cursor: pointer;
                    color: #555555;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        span {
                            color: #20B99C;
                        }
                    }

                    i {
                        font-size: 20px;
                    }

                }
            }
        }
    }
}

#agency {
    background-image: url('../public/img/agencyImg.jpg');
    background-attachment: fixed;
    background-size: contain;
    /* Optional: Adjusts the size of the background image */
    background-position: center;
    height: calc(100vh / 2);
}

#about {
    padding: 100px 20px;

    .aboutContent {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
        padding: 0 120px;

        .aboutItem {
            width: 300px;
            height: 200px;
            position: relative;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .overlay {
                background: rgba(0, 0, 0, 0.4);
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                transition: all .2s;
                left: 0;
                transform: translateX(100%);
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 24px;
                padding: 30px;
            }

            &:hover {
                .overlay {
                    transform: translateX(0);

                }
            }

        }
    }
}

#ourTeam {
    padding: 30px 0;

    h1 {
        font-size: 60px;
        text-align: center;

    }

    .content {
        margin: 30px auto;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .team {
            width: 340px;
            padding: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;
            flex-wrap: wrap;

            .img {
                width: 200px;
                height: 200px;



                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;

                }
            }

            h3 {
                color: #555555;
            }

            p {
                color: silver;
            }

            .menuIcon {
                display: flex;
                align-items: center;
                gap: 10px;

                i {
                    width: 32px;
                    height: 32px;
                    color: #666666;
                    background-color: #EEF4F8;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }
}

#contact {
    width: 100%;
    padding: 0 50px 0px;
    background-color: black;


    .contactContent {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgb(53, 53, 53);


        .info {
            width: 500px;

            padding: 30px;

            .logo {
                img {
                    height: 80px;
                    width: 100%;
                }

            }

            h1 {
                color: white;
            }

            p {
                margin: 20px 0;
                color: white;
            }

            a {
                text-decoration: none;
                color: white;
                margin: 10px 0;
                display: block;
            }

        }

        .contactIcon {
            .icon {
                width: 180px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(53, 53, 53);
                transition: all .5s;
                cursor: pointer;
                border-bottom: none;

                i {
                    font-size: 20px;
                    color: white;
                    transition: all .5s;

                }

                &:hover {
                    background-color: white;

                    i {
                        color: black;
                    }
                }
            }

        }

    }

    .bigLogo {
        width: 100%;
        color: white;
        font-size: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer {
        padding: 0 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;

        ul {
            display: flex;
            gap: 40px;

            li {
                list-style: none;
                font-size: 20px;

                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }


    }

    p {
        color: rgb(232, 232, 232);
        text-align: center;
        font-size: 10px;
        padding-bottom: 10px;
    }
}

#megaMenu {
    background-color: rgb(10, 10, 10);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    height: 100hv;
    transition: all .5s;
    transform: translateY(100%);

    button {
        position: absolute;
        top: 40px;
        right: 40px;
        background-color: transparent;
        border: none;

        cursor: pointer;

        i {
            color: white;
            font-size: 30px;
        }


    }

    .img {
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 50px;

        .item {
            ul {
                li {
                    // list-style: none;
                    margin: 20px 0;
                    color: white;



                    a {
                        color: white;
                        font-size: 24px;
                        text-decoration: none;
                    }
                }
            }

            .phone,
            .email {
                margin: 20px auto;

                a {
                    color: white;
                    text-decoration: none;
                    font-size: 18px;


                }
            }

            h3 {
                color: rgb(159, 159, 159);
                text-align: center;
                margin: 100px auto 0;
                font-weight: 300;
            }
        }
    }
}

//
.sliderBar {
    height: 2px;
    width: 100px;
    background-color: black;
    margin-top: 30px;
    position: relative;

    .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #00F0FF;
        position: absolute;
        top: -4px;
        left: 40px;
        animation: transformRight 4s infinite;
    }
}

@media screen and (max-width: 576px) {
    #header {
        background-color: black;
        position: sticky;
        top: 0;
        z-index: 2000;

        .menu {
            margin: 0 20px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .logo {
                color: white;
                font-size: 60px;
                display: none;

            }

            .menuContent {
                display: none;

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 50px;


                    li {
                        color: white;
                        font-size: 18px;
                        list-style: none;
                        cursor: pointer;

                        &::after {
                            content: '';
                            height: 1px;
                            background-color: #00F0FF;
                            width: 0;
                            margin-top: 2px;
                            display: block;
                            transition: all .5s;
                        }

                        &:hover {
                            &::after {
                                width: 100%;

                            }
                        }
                    }
                }
            }

            .email {
                a {
                    text-decoration: none;
                    color: white;

                    i {
                        color: white;
                    }
                }
            }

        }

        // .animateMenuIconWrap {
        //     // position: relative;

        //     .animateMenuIcon {
        //         height: 120px;
        //         width: 120px;
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         background-color: #00F0FF;
        //         z-index: 1;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         border-radius: 50%;
        //         transform: translateX(-40px);
        //         animation: moveRight 5s linear infinite;
        //     }

        //     .menuBar {
        //         position: fixed;
        //         top: 50px;
        //         left: 40px;
        //         color: white;
        //         font-size: 24px;
        //         z-index: 2;

        //     }
        // }

    }

    #banner {
        // height: 815px;
        padding: 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-direction: column;

        .leftBanner {
            width: 100%;
            padding: 5px;


            h1.mainTitle {
                margin-top: 40px;
                width: 100%;
                padding: 5px;

                // height: 240px;
                font-weight: 600;
                font-size: 38px;
                animation: opacity 2s;
            }

            p {
                margin-top: 20px;
                font-size: 18px;
                padding-right: 20px;
                animation: opacity 3s;
                color: #555555;
                padding: 10px;
            }


            button {
                margin-top: 30px;
                padding: 10px 20px;
                background-color: #20B99C;
                color: white;
                font-size: 18px;
                border: none;
                border-radius: 16px 0 16px 0;
            }
        }

        .bannerImg {
            width: 100%;
            height: 100%;

            padding: 5px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 80px 0 80px 0;
            }
        }

    }

    #services {
        padding: 0 20px 20px;
        display: flex;
        gap: 20px;
        justify-content: center;
        flex-direction: column;

        .serviceItem {
            padding: 40px;
            width: 100%;
            border: 1px solid silver;
            cursor: pointer;
            transition: all .5s;

            img {
                margin-bottom: 25px;
                filter: grayscale(1);
                transition: all .5s;

            }

            h4 {
                margin-bottom: 15px;
                transition: all .5s;

            }

            p {
                color: #555555;
                transition: all .5s;
                padding: 10px;

            }

            &:hover {
                background-color: black;

                img {
                    scale: 1.1;


                }

                h4,
                p {
                    color: white;
                    transform: translateY(-5px);
                    scale: 1.01;
                }
            }

        }
    }


    #projects {
        padding: 20px 0 20px;

        .projectContent {
            flex-direction: column;


            .leftProject {
                width: 100%;

                padding: 5px;

                h1 {
                    font-size: 50px;
                }

                img {
                    width: 100%;
                }
            }

            .rightProject {
                width: 100%;

                padding: 5px;

                p {
                    padding: 10px;
                }

                ul {
                    padding: 20px;

                    li {
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid silver;
                        list-style: none;
                        cursor: pointer;
                        color: #555555;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            span {
                                color: #20B99C;
                            }
                        }

                        i {
                            font-size: 16px;
                        }

                    }
                }
            }
        }
    }

    #agency {
        background-size: contain;
        background-attachment: scroll;
        background-repeat: no-repeat;


    }

    #about {
        padding: 20px;

        .aboutContent {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            flex-wrap: wrap;

            padding: 0;

            .aboutItem {
                width: 100%;
                // height: 200px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }

                .overlay {
                    background: rgba(0, 0, 0, 0.4);
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    transition: all .2s;
                    left: 0;
                    transform: translateX(100%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-size: 24px;
                    padding: 30px;
                }

                &:hover {
                    .overlay {
                        transform: translateX(0);

                    }
                }

            }
        }
    }

    #ourTeam {
        .content {
            flex-direction: column;
        }
    }

    #contact {
        padding: 5px;
        background-color: black;



        .contactContent {
            flex-direction: column;
            gap: 40px;


            .info {
                width: 100%;
                padding: 10px;

                h1 {
                    color: white;
                }

                p {
                    color: white;
                    padding: 20px
                }

                a {
                    text-decoration: none;
                    color: white;
                    margin: 10px 0;
                    display: block;
                }

            }

            .contactIcon {
                display: flex;
                gap: 20px;

                .icon {
                    width: 60px;
                    height: 40px;
                    border: 1px solid rgb(53, 53, 53);


                    i {
                        font-size: 20px;
                        color: white;
                        transition: all .5s;

                    }

                    &:hover {
                        background-color: white;

                        i {
                            color: black;
                        }
                    }
                }

            }

        }

        .bigLogo {
            width: 80%;
            margin: 0 auto;
            color: white;
            font-size: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .footer {
            margin-top: 200px;
            padding: 5px;
            flex-direction: column-reverse;
            align-items: flex-start;
            padding: 10px;



            ul {
                display: block;
                margin: 100px 0;
                border-bottom: 1px solid silver;
                width: 100%;

                li {
                    list-style: none;
                    font-size: 16px;
                    margin: 10px;


                }
            }

            h3 {
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                text-align: center;
            }
        }
    }

    #megaMenu {
        .img {
            margin-top: 60px;
            width: 100%;
            padding: 20px;

            img {
                width: 90%;
            }
        }

        ul {
            margin-left: 20px;

        }

        .content {
            flex-direction: column;
            align-items: flex-start;
            padding: 20px;

            .item {
                ul {
                    li {
                        // list-style: none;
                        margin: 10px 0;
                        color: white;



                        a {
                            color: white;
                            font-size: 24px;
                            text-decoration: none;
                        }
                    }
                }

                .phone,
                .email {
                    margin: 10px auto;

                    a {
                        color: white;
                        text-decoration: none;
                        font-size: 18px;


                    }
                }

                h3 {
                    color: rgb(159, 159, 159);
                    text-align: center;
                    margin: 50px auto 0;
                    font-weight: 300;
                }
            }

        }
    }


}


@keyframes moveRight {
    0% {
        transform: translateX(-40px);
    }

    50% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(-40px);
    }


}

@keyframes opacity {
    0% {
        opacity: 0;
        ;
    }

    50% {
        opacity: .5;
        ;
    }

    100% {
        opacity: 1;
    }


}

@keyframes transformRight {
    0% {
        transform: translateX(60px)
    }

    50% {
        transform: translateX(0px)
    }

    100% {
        transform: translateX(60px)
    }


}